import lastonion from '../apis/lastonion';
import selectors from '../selectors';

export const fetchRecipes = () => async(dispatch, getState) => {
    
    // Options to pass for headers
    const options = {
        headers: {
            'x-api-key': 'A1Laaq3DP152MgNpWec4H3tla6WeE4LP591FLEyz',
            'Content-Type': 'application/json'
        }
      };

    // Create the correct search object expected by the API
    // TODO: We are not going to bother with shopping list right now
    let cupboardItems = selectors.getAllCupboardItems(getState());
    let cupboardIngredients = [];
    let shoppingListIngredients =[];
    let cupboardCategories = [];
    
    // Filter the cupboard items to find ingredients and categories
    cupboardItems.filter(item => item.category ==='false').forEach(item => cupboardIngredients.push(item.ingredient))
    cupboardItems.filter(item => item.category ==='true').forEach(item => cupboardCategories.push(item.ingredient))

    // Send cupboard ingredients, shopping list items and categories
    const searchObject = {
        "cupboard": cupboardIngredients,
        "shoppinglist":shoppingListIngredients,
        "categories":cupboardCategories
    }
    
    // Call API if we have something to search based on
    if (searchObject.cupboard.length> 0 || searchObject.shoppinglist.length >0 || searchObject.categories.length >0)
    {
        const response = await lastonion.post('/recipematch?api-version=1.0',searchObject,options);
        dispatch(
            {
                type: 'FETCH_RECIPES',
                payload: response.data.recipeMatch
            }
        )
    }
    else
    {
        // Either we have deleted the last ingredient (we need to reset search) or the page has just loaded
        dispatch(
            {
                type: 'CLEAR_RECIPES',
                payload: []
            }
        )
    }
    
};