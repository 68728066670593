import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import throttle from 'lodash.throttle';

import App from './app/App';
import reducers from './app/reducers';
import { loadState, saveState } from './app/modules/localstoragesync';

import "./styles/index.css";


// Load state from local storage
const persistedState = loadState();

// Add in dev tools                 
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create the store, adding in persistant state and middleware
const store = createStore(reducers, persistedState, composeEnhancers(applyMiddleware(thunk)));

// Subscribe to changes to update local storage - but just save the cupboard, with rate limiting
store.subscribe(throttle(() => {
  saveState({
    cupboard: store.getState().cupboard
  });
}, 1000));



ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.querySelector('#root')
);