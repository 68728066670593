import React from 'react';
import Image from './common/Image';

import img from "../images/logo.png";

class Header extends React.Component {

    render() {
        return (
            <header className="header lo__wrapper">
                <Image 
                    src={img}
                    alt="Last Onion"
                    classOverride="header__logo"
                />
            </header>
        );
    }
}


export default Header;