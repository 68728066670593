import lastonion from '../apis/lastonion';

export const fetchIngredientSuggestions = ingredientSearchTerm => {
    return async dispatch => {

        // Options to pass for headers
        const options = {
            headers: {
                'x-api-key': 'A1Laaq3DP152MgNpWec4H3tla6WeE4LP591FLEyz',
                'Content-Type': 'application/json'
            }
          };

        // Test data to get a valid API response
        const postData = {
            "ingredient": ingredientSearchTerm ? ingredientSearchTerm : ""
        }


        const response = await lastonion.post('/ingredientmatch?api-version=1.0',postData,options);
        dispatch(
            {
                type: 'FETCH_INGREDIENT_SUGGESTIONS',
                payload: response.data.ingredientMatch
            }
        ) 
    }
};