import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import { fetchRecipes } from '../actions/RecipeActions';
import { fetchIngredientSuggestions } from '../actions/IngredientActions';
import { addIngredientToCupboard, clearIngredientSuggestions } from '../actions/CupboardActions';


class SearchBar extends React.Component {
    /* 
        TODO: 
        - Only search when number of chars > threshold
        - Show loading when fetching from API
        - Close suggestions when user clicks away
    */
    constructor(props) {
        super(props);
        // Create a ref for the text input as i'll need to get the data out to call the autocomplete API
        this.textInput = React.createRef();
        
        // Debounce the autocomplete
        this.handleInputChangeDebounced = debounce(this.handleInputChange, 200)
    }
  
    handleInputChange = () => {
      // When we change the input box, call the API for suggestions
      this.props.fetchIngredientSuggestions(this.textInput.current.value);
    }
    
    clearSearchResults = () => {
        // Clear the input box and the 'drop down' list
        this.textInput.current.value='';
        this.props.clearIngredientSuggestions({
            type:'CLEAR_INGREDIENT_SUGGESTIONS'
        });
    }
    
    callRecipeSearch =() => {
        this.props.fetchRecipes([]);
    }
    
    handleSuggestionClick = (suggestion) => {
        this.props.addIngredientToCupboard({
            type:'ADD_INGREDIENT_TO_CUPBOARD',
            payload: suggestion
        });
        this.clearSearchResults();
        this.callRecipeSearch();
    }
 
    renderSuggestions(){
        // Render the list of suggestions as an li
        const {ingredientSuggestions} = this.props;
        
        return !!ingredientSuggestions.length && 
            <div className="suggestions">
                {ingredientSuggestions.map(suggestion =>{
                    return (
                        <div className="suggestions__items"
                            key={suggestion.id} 
                            data-searchterm={suggestion.ingredient} 
                            data-searchid={suggestion.id} 
                            onClick={() => this.handleSuggestionClick(suggestion)}>
                            {suggestion.ingredientDisplayName}
                        </div>
                    );
                })}
            </div>
    }
    
    render (){
        return (
            <div className="lo__wrapper search">
                <section className="lo__section lo__section--centered search-bar">
                    <input
                        id="suggestion_input"
                        type="text"
                        className="form__input form__input--text search-bar__input"
                        ref={this.textInput} 
                        placeholder="What's in your cupboard? Pick at least two..." 
                        onChange={this.handleInputChangeDebounced}
                    />
                    {this.renderSuggestions()}
                </section>
            </div>
        );
    }
}

const mapStateToProps = function(state) {
    return {ingredientSuggestions: state.ingredientSuggestions};
}


export default connect(mapStateToProps,{fetchIngredientSuggestions, fetchRecipes,addIngredientToCupboard, clearIngredientSuggestions})(SearchBar);