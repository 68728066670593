import React from 'react';
import { connect } from 'react-redux';

import { fetchRecipes } from './actions/RecipeActions';
import { removeIngredientFromCupboard } from './actions/CupboardActions';
import selectors from './selectors';


class Cupboard extends React.Component {


    callRecipeSearch = () => {
        this.props.fetchRecipes([]);
    }
    
    handleCupboardItemClick = (cupboardItem) => {
        this.props.removeIngredientFromCupboard({
            type:'REMOVE_INGREDIENT_FROM_CUPBOARD',
            payload: cupboardItem
        });
        this.callRecipeSearch();
    }
    
    renderCupboard() {
        const {cupboard} = this.props;
        
        return !!cupboard.length && 
            <ul className="lozenge-list">
                {cupboard.map(cupboardItem => {
                    const {
                        id, 
                        ingredient,
                        ingredientDisplayName
                    } = cupboardItem;
                    return (
                        <li 
                            className={`cupboard__list__item lozenge`}
                            key={id} 
                            data-searchterm={ingredient} 
                            data-searchid={id} 
                            onClick={ () => this.handleCupboardItemClick(cupboardItem)}>
                            <div className="content">
                                {ingredientDisplayName}
                                <i className="delete icon"></i>
                            </div>
                        </li>
                    );
                })}
            </ul>
    }
    
    render () {
        return (
            <div className="lo__wrapper cupboard">
                <section className="lo__section">
                    {this.renderCupboard()}
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cupboard: selectors.getCupboard(state)
    };
}

export default connect(mapStateToProps,{ removeIngredientFromCupboard, fetchRecipes}) (Cupboard);