export const getRecipeStats = (recipe, cupboard) => {
    let matchedCupboardIngredients=0;
    let matchedCupboardCategories=0;
    let requiredIngredients=0;
    
    // Get cupboard ingredients, recipe categories and recipe ingredients as arrays
    let cupboardIngredients = cupboard.map(a => a.ingredient.toLowerCase());
    let recipeIngredients = recipe.ingredientsrequired.split(" ");
    let recipeCategories = recipe.categoriesrequired.split(" ");
    
    // Get total required
    requiredIngredients = recipeIngredients.length;
    matchedCupboardIngredients = recipeIngredients.filter(i => cupboardIngredients.includes(i)).length;
    matchedCupboardCategories = recipeCategories.filter(i => cupboardIngredients.includes(i)).length;
    let matchedIngredients = matchedCupboardIngredients + matchedCupboardCategories;
    let missingIngredients = requiredIngredients-matchedIngredients;
    console.log(cupboardIngredients)
    console.log(recipeIngredients)
    
    //TODO: Work how how many match between cupboard and recipe and make this also available for the UI to show crossed out
    
    return `You have ${matchedIngredients} out of ${requiredIngredients} ingredients required, you need ${missingIngredients} more ingredients!`;
};
