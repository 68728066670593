import React, {Component} from 'react';
import ReactDOM from 'react-dom';

const modalRoot = document.getElementById('modal-root');

class Modal extends Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.el.className = "modal";
    }
    
    componentDidMount() {
        modalRoot.appendChild(this.el);
    }
    
    componentWillUnmount() {
        modalRoot.removeChild(this.el);
    }
    
    handleToggleModal = () => {
        this.props.toggleModal();
    }
    
    render() {
        return ReactDOM.createPortal(
            <div className="modal__container">
                <h2 className="modal__title">{this.props.title}</h2>
                {this.props.children}
                <div 
                    className="modal__close" 
                    onClick={this.handleToggleModal}>
                    <i className="delete icon"></i>
                </div>
            </div>,
          this.el
        );
    }
}


export default Modal;