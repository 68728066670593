export default (state = [], action) => {
    switch(action.type){
        case 'ADD_INGREDIENT_TO_CUPBOARD':
            // We need to take the item here and add it to state 
            // In a pure manner (so we don't mutate)
            return [...state,action.payload]
        case 'REMOVE_INGREDIENT_FROM_CUPBOARD':
            // We need to remove the item here and return resultant state 
            // In a pure manner (so we don't mutate)
            
            // Get the Id to remove
            let idToRemove = action.payload.id;
            
            // Find it in the array of objects
            const index = state.map(state => state.id).indexOf(idToRemove);
            
            // carve the array up to return a new one..
            return [
                ...state.slice(0,index),
                ...state.slice(index+1)
                ]
        default:
            return state;
    }
}