import selectors from '../selectors';

export const addIngredientToCupboard = (ingredientToBeAdded) => (dispatch, getState) => {
    
        
        if (selectors.isItemInCupboard(getState(),ingredientToBeAdded)) {
            return;
        }
        else
        {
            dispatch(ingredientToBeAdded);
        }

};

export const removeIngredientFromCupboard = ingredientToBeRemoved => {
    return dispatch => {
        dispatch(ingredientToBeRemoved) 
    }
};

export const clearIngredientSuggestions = () => {
    return dispatch => {
        dispatch(
            {
                type: 'CLEAR_INGREDIENT_SUGGESTIONS',
                payload: {}
            }
        ) 
    }
};