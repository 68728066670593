
const getCupboard = state => {
    return state.cupboard;
}

const getCupboardIngredients = state => {
    return getCupboard(state).map(item => item.ingredient);
}

const getAllCupboardItems = state => {
    return getCupboard(state).map(item => item);
}

const isItemInCupboard = (state,itemToCheck) => {
    // Check to see if item is in cupboard already
    if(getCupboard(state).find(item => item.id === itemToCheck.payload.id))
    {
        return true;
    }
    else
    {
        return false;
    }
}

const selectors = {
    getCupboard, 
    getCupboardIngredients,
    getAllCupboardItems,
    isItemInCupboard
};

export default selectors;