import React from 'react';
class Blurb extends React.Component {

    render(){
        return (
            <div className="blurb lo__wrapper">
                <section className="lo__section lo__section--centered">
                    <p><strong>Steam it, Grill it, Fry it, Braise it, Blanch it, Bake it...</strong> <em>just don't bin it.</em></p>
                    <p>We've put Last Onion together to help prevent food waste whilst offering you some cooking inspiration.</p>
                </section>
            </div>
            );
    }
}


export default Blurb;