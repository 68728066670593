import React from 'react';

const Image = (props) => {
    const { src, alt, title, classOverride, defaultImg } = props;
    const defaultImage = defaultImg || "https://images.unsplash.com/photo-1514986888952-8cd320577b68?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1655&q=80";
    
    const hasImage = !!src;
    const imageSrc = hasImage ? src : defaultImage;
    const imageAlt = hasImage ? alt : "Placeholder image";
    const classes = classOverride ? classOverride : "";
    
    return (
        <figure className={`lo__image ${classes}`}>
            <img 
                src={imageSrc} 
                alt={imageAlt} />
            {title && <figcaption>{title}</figcaption>}
        </figure>
    )
}


export default Image;