import React from 'react';
import { connect } from 'react-redux';

import Image from './common/Image';
import { fetchRecipes } from './actions/RecipeActions';
import selectors from './selectors';
import { getRecipeStats } from './utils/recipe-stats';

class RecipeList extends React.Component {
    componentDidMount(){
        this.props.fetchRecipes();
    }

    renderList(){
        const { recipes, cupboard } = this.props;
        return recipes.map(recipe =>{
            const recipeStats = getRecipeStats(recipe, cupboard);
            return (
                <div className="recipe-item" key={recipe.id}>
                    <article>
                        <h2><a href={recipe.url} target="_blank" rel="noopener noreferrer">{recipe.title}</a></h2>
                        <div>{recipeStats}</div>
                        <p>
                            {recipe.ingredients.join(', ')}
                        </p>
                        <p><a href={recipe.url} target="_blank" rel="noopener noreferrer">Click here to visit recipe on {recipe.originname} (new window)</a></p>
                    </article>
                    <a href={recipe.url} target="_blank" rel="noopener noreferrer" className="recipe-item__img">
                        <Image 
                            src={recipe.imageurl}
                            alt={recipe.title}
                            title={recipe.title + ' - ' + recipe.originname}
                        />
                    </a>
                </div>
            );
        });
    }

    render(){
        return (
            <div className="lo__wrapper recipe-list">
                <div className="lo__section">
                    {this.renderList()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        recipes: state.recipes,
        cupboard: selectors.getCupboard(state)
    };
}

export default connect(
    mapStateToProps,
    { fetchRecipes }
)(RecipeList);