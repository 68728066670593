import React from 'react';
import RecipeList from './RecipeList'
import SearchBar from './search/SearchBar'
import Cupboard from './Cupboard'
import Header from './Header'
import Blurb from './Blurb'
import Footer from './Footer';
import Konami from 'konami';

/* eslint-disable no-unused-vars */
const easter_egg = new Konami(function() { alert('Konami Code!')});
/* eslint-enable no-unused-vars */

const App = () => {
    return (
        <div className="app">
            <Header/>
            <Blurb/>
            <SearchBar/>
            <Cupboard/>
            <RecipeList ingredientSearchTerm="ch"/>
            <Footer/>
        </div>
    );
};

export default App;